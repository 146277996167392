<template>
  <div>

    <div class="search-box">
      <div class="shouqi" @click="status = !status">
        <span v-if="status">收起</span>
        <span v-else>
          展开更多
        </span>
      </div>

      <div class="tabs">
        <div v-show="status">
          <el-row>
            <el-col :span="2"><span class="title">所属地区></span></el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.city_id.length === 0 }" @click="choose('city_id', -1, 1)">全部</span>
              <span v-for="(item, i) in cityList" :key="i" :class="{ act: check.city_id.indexOf(item.city_code) > -1 }"
                @click="choose('city_id', item.city_code, 1)">{{ item.city }}</span>
            </el-col>
          </el-row>
          <el-divider class="search_line"></el-divider>

          <el-row>
            <el-col :span="2"><span class="title">院校类型></span></el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.school_type_id.length === 0 }"
                @click="choose('school_type_id', -1, 1)">全部</span>
              <span v-for="(item, i) in schoolTypeList" :key="i"
                :class="{ act: check.school_type_id.indexOf(item.id) > -1 }"
                @click="choose('school_type_id', item.id, 1)">{{ item.name }}</span>
            </el-col>
          </el-row>
          <el-divider class="search_line"></el-divider>

          <el-row>
            <el-col :span="2">
              <span class="title">院校性质></span>
            </el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.nature_id === null }"
                @click="choose('nature_id', null, 0)">全部</span>
              <span :class="{ item: true, act: check.nature_id === 1 }" @click="choose('nature_id', 1, 0)">公办</span>
              <span :class="{ item: true, act: check.nature_id === 2 }" @click="choose('nature_id', 2, 0)">民办</span>
              <span :class="{ item: true, act: check.nature_id === 3 }" @click="choose('nature_id', 3, 0)">中外合作办学</span>
              <span :class="{ item: true, act: check.nature_id === 4 }"
                @click="choose('nature_id', 4, 0)">内地与港澳台地区合作办学</span>
            </el-col>
          </el-row>
          <el-divider class="search_line"></el-divider>


          <el-row>
            <el-col :span="2"><span class="title">院校特色></span></el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.schoolTipId.length === 0 }"
                @click="choose('schoolTipId', -1, 1)">全部</span>
              <span v-for="(item, i) in schoolTipList" :key="i" :class="{
                item: true,
                act: check.schoolTipId.indexOf(item.id) > -1,
              }" @click="choose('schoolTipId', item.id, 1)">{{ item.name }}
              </span>
            </el-col>
          </el-row>
          <el-divider class="search_line"></el-divider>
          <el-row>
            <el-col :span="2">
              <span class="title">招生快筛></span>
            </el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.teshu === null }" @click="choose('teshu', null, 0)">全部</span>
              <span :class="{ item: true, act: check.teshu === 1 }" @click="choose('teshu', 1, 0)">普通类</span>
              <span :class="{ item: true, act: check.teshu === 2 }" @click="choose('teshu', 2, 0)">国家专项</span>
              <span :class="{ item: true, act: check.teshu === 3 }" @click="choose('teshu', 3, 0)">地方专项</span>
              <span :class="{ item: true, act: check.teshu === 4 }" @click="choose('teshu', 4, 0)">定向就业</span>
              <span :class="{ item: true, act: check.teshu === 5 }" @click="choose('teshu', 5, 0)">预科班</span>
              <span :class="{ item: true, act: check.teshu === 6 }" @click="choose('teshu', 6, 0)">合作办学</span>
              <span :class="{ item: true, act: check.teshu === 7 }" @click="choose('teshu', 7, 0)">单列专业</span>
              <span :class="{ item: true, act: check.teshu === 8 }" @click="choose('teshu', 8, 0)">其他</span>
            </el-col>
          </el-row>
          <el-divider class="search_line"></el-divider>
          <el-row v-if="check.type != 0">
            <el-col :span="2">
              <span class="title">推荐志愿></span>
            </el-col>
            <el-col :span="21">
              <span :class="{ item: true, act: check.level === null }" @click="choose('level', null, 0)">全部</span>
              <span :class="{ item: true, act: check.level === 1 }" style="color: #BB0000;"
                @click="choose('level', 1, 0)">难</span>
              <span :class="{ item: true, act: check.level === 2 }" @click="choose('level', 2, 0)">冲一冲</span>
              <span :class="{ item: true, act: check.level === 3 }" @click="choose('level', 3, 0)">稳一稳</span>
              <span :class="{ item: true, act: check.level === 4 }" @click="choose('level', 4, 0)">保一保</span>
            </el-col>
          </el-row>
          <el-divider v-if="check.type != 0" class="search_line"></el-divider>
        </div>
        <el-row>
          <el-col :span="2"><span class="title">填报模式></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.type === 0 }" @click="choose('type', 0, 0)">顺序模式</span>
            <span :class="{ item: true, act: check.type === 1 }" @click="choose('type', 1, 0)">推荐模式</span>
          </el-col>
        </el-row>

      </div>
      <div class="specialty-search tianbao-search">
        <span class="span-name">院校名称：</span>
        <el-input placeholder="请输入院校名称" style="width:210px;margin-right:20px" v-model="check.name" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>



        <span class="span-name" v-if="check.type == 0">位次区间</span>
        <el-input placeholder="最高位次" v-if="check.type == 0" style="width:100px;margin-right:00px"
          v-model="check.min_ranking" size="mini" @keyup.enter.native="doSearch">
        </el-input>

        <span style="color: #aeb6ae;" v-if="check.type == 0">-</span>
        <el-input placeholder="最低位次" v-if="check.type == 0" style="width:100px;margin-right:5px"
          v-model="check.max_ranking" size="mini" @keyup.enter.native="doSearch">
        </el-input>
        <span style="color: #aeb6ae;position: relative;top: 2px;">{{ num }}</span>

      </div>
      <div class="specialty-search tianbao-search" style="margin-top: 20px;">
        <span class="span-name">搜索专业:</span>
        <el-input placeholder="专业1" style="width:100px;margin-right:20px" v-model="check.specialty[0]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span class="span-name">2:</span>
        <el-input placeholder="专业2" style="width:100px;margin-right:20px" v-model="check.specialty[1]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span class="span-name">3:</span>
        <el-input placeholder="专业3" style="width:100px;margin-right:20px" v-model="check.specialty[2]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span class="span-name">4:</span>
        <el-input placeholder="专业4" style="width:100px;margin-right:20px" v-model="check.specialty[3]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span class="span-name">5:</span>
        <el-input placeholder="专业5" style="width:100px;margin-right:20px" v-model="check.specialty[4]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span class="span-name">6:</span>
        <el-input placeholder="专业6" style="width:100px;margin-right:20px" v-model="check.specialty[5]" size="mini"
          @keyup.enter.native="doSearch">
        </el-input>
        <span style="float:right; background:#2b7bf3;color:white;padding: 3px 12px;margin-right:0px" size="mini"
          @click="getReport()">已选志愿</span>
        <span style="float:right; background:#2b7bf3;color:white;padding: 3px 12px; margin-right:20px" size=" mini"
          @click="doSearch">搜索</span>
      </div>
    </div>
  </div>
</template>

<script>
import cityList from "@/assets/data/city_data";

export default {
  name: "",
  props: {
    value: Object,
    num: Number
  },
  data() {
    return {
      status: true,
      cityList: cityList,
      recruitUnitList: [
        {
          id: 2,
          name: "较高收费",
        },
        {
          id: 7,
          name: "其他单列",
        },
        {
          id: 4,
          name: "异地校区",
        },
        {
          id: 5,
          name: "医护类",
        },
        {
          id: 3,
          name: "少数民族",
        },
      ],
      schoolTypeList: [
        {
          id: "17",
          name: "财经类",
        },
        {
          id: "18",
          name: "理工类",
        },
        {
          id: "19",
          name: "农林类",
        },
        {
          id: "20",
          name: "综合类",
        },
        {
          id: "21",
          name: "政法类",
        },
        {
          id: "22",
          name: "师范类",
        },
        {
          id: "23",
          name: "语言类",
        },
        {
          id: "24",
          name: "医药类",
        },
        {
          id: "26",
          name: "民族类",
        },
        {
          id: "27",
          name: "艺术类",
        },
        {
          id: "28",
          name: "体育类",
        },
        {
          id: "29",
          name: "军事类",
        },
      ],
      schoolTipList: [
        {
          id: "11",
          name: "C9",
          type: "0",
        },
        {
          id: "5",
          name: "985",
          type: "0",
        },
        {
          id: "6",
          name: "211",
          type: "0",
        },
        {
          id: "7",
          name: "双一流A",
          type: "0",
        },
        {
          id: "12",
          name: "双一流B",
          type: "0",
        },
        {
          id: "13",
          name: "国家示范",
          type: "0",
        },
        {
          id: "14",
          name: "国家骨干",
          type: "0",
        },
        {
          id: "15",
          name: "双高计划",
          type: "0",
        },
      ],
      check: {

      },

    };
  },
  computed: {},
  created() {
    this.check = this.value
  },

  methods: {

    //选择条件
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
    getReport() {
      this.$emit('getReport')
    }

  },
};
</script>

<style scoped lang='less'>
.old_search {
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 3px 7px;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 10px;
}

.search-box {
  margin: 0 0 20px;
  position: relative;

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .shouqi {
    position: absolute;
    right: 10px;
    // top: 40px;
    z-index: 1;
    color: #459EFF;
    font-size: 12px;
  }

  .tabs {
    color: #666666;
    letter-spacing: 2px;
    padding: 15px 0 0 0;
    margin-bottom: 15px;
    font-size: 16px;
    position: relative;

    .title {
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 6px;
      line-height: 26px;
      margin-bottom: 3px;
      margin-right: 6px;

      &:hover {
        background: #2B7BF3;
        color: white;
      }
    }
  }

  .act {
    background-color: #2B7BF3;
    color: #ffffff;
  }
}

.search_line {
  margin: 7px 0;
}

.tianbao-search {
  font-size: 16px;
  padding: 0 6px;

  .span-name {
    color: #A7A7A7;
    margin-right: 15px;
    position: relative;
    top: 4px;
  }
}
</style>
